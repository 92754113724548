<template>
	<AuthLayout>
		<nav aria-label="breadcrumb my-3">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<router-link tag="a" :to="{ name: 'users' }">
						<span>Users</span>
					</router-link>
				</li>
				<li class="breadcrumb-item active">Create Account</li>
			</ol>
		</nav>
		<div class="row">
			<div class="col-6 offset-3">
				<form class="user" @submit.prevent="onSubmit">

					<TextField
						:value="user.first_name"
						@change="event => inputChanged({
							key: 'first_name',
							value: event
						})"
						label="First Name" 
						:errors="errors.first_name"
					/>

					<TextField 
						:value="user.last_name"
						@change="event => inputChanged({
							key: 'last_name',
							value: event
						})"
						label="Last Name" 
						:errors="errors.last_name"
					/>

					<TextField 
						:value="user.username"
						@change="event => inputChanged({
							key: 'username',
							value: event
						})"
						label="User Name" 
						:errors="errors.username"
					/>

					<TextField 
						:value="user.email"
						@change="event => inputChanged({
							key: 'email',
							value: event
						})"
						type="email" 
						label="Email Address" 
						:errors="errors.email"
					/>

					<TextField 
						:value="user.mobile"
						@change="event => inputChanged({
							key: 'mobile',
							value: event
						})"
						type="number"
						label="Mobile Number" 
						:errors="errors.mobile"
					/>

					<TextField 
						:value="user.password"
						@change="event => inputChanged({
							key: 'password',
							value: event
						})"
						type="password"
						label="Password" 
						:errors="errors.password"
					/>

					<div class="form-group">
						<label for="state">User Status</label>
						<select 
							:value="user.user_status"
							@change="event => inputChanged({
								key: 'user_status',
								value: event.target.value
							})"
							id="userStatusSelect"
							:class="[{ 'is-invalid': errors.user_status }, 'form-control rounded-10']"
						>
							<option value="" disabled selected>
								Please Select
							</option>
							<option 
								v-for="(status, index) in userStatuses" 
								:key="index" 
								:value="status" 
								class="text-capitalize"
							>
								{{ status }}
							</option>
						</select>
						<div class="text-danger text-left d-flex mt-1" v-if="errors.user_status">
							{{ errors['user_status'][0] }}
						</div>
					</div>

					<div class="form-group">
						<label for="state">User Type</label>
						<select 
							:value="user.user_type"
							@change="event => inputChanged({
								key: 'user_type',
								value: event.target.value
							})"
							id="userTypeSelect"
							:class="[{ 'is-invalid': errors.user_type }, 'form-control rounded-10']"
						>
							<option value="" disabled selected>
								Please Select
							</option>
							<option 
								v-for="(type, index) in userTypes" 
								:key="index" 
								:value="type" 
								class="text-capitalize"
							>
								{{ type }}
							</option>
						</select>
						<div class="text-danger text-left d-flex mt-1" v-if="errors.user_type">
							{{ errors['user_type'][0] }}
						</div>
					</div>

					<div class="py-5">
						<Button 
                            type="submit" 
                            color="success" 
                            :loading="loadingFlag" 
                            block
                        >
                            Create User Account
                        </Button>

						<Button 
                            color="danger" 
                            :loading="loadingFlag" 
                            block
							@click.native="onCancel"
                        >
                            Cancel Creation
                        </Button>
					</div>
				</form>
			</div>
		</div>
	</AuthLayout>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import swal from 'sweetalert'

	export default {
		name: "CreateUser",

		computed: {
			...mapGetters('user', [
				'user',
				'userStatuses',
				'userTypes',
				'errors',
				'loadingFlag'
			])
		},

		methods: {
			...mapActions('user', [ 'inputChanged' ]),

			async onSubmit() {
				await this.$store.dispatch('user/createUser', {
					onSuccess: () => {
						swal("User Created Successfully", "Congratulations for another customer, Lifebox Admin.", "success")
						this.$router.push({ name: 'users' })
					},
					onFailed: () => {
						swal("User Creation Failed", "Please fill in the missing / incorrect details and try again.", "error")
					}
				})
			},

			async onCancel() {
				const message = {
					title: 'Are you sure you want to cancel creating this user?',
					text: "All details put to this user account will not be saved after this confirmation.",
					icon: 'warning',
					buttons: true,
					dangerMode: true
				}

				if (await swal(message)) {
					await this.$store.dispatch('user/clearUser')
					this.$router.push({ name: 'users' })
				}
			},
		},

		async beforeRouteEnter(to, from, next) {
			try {
				const userID = to.params.userID
				next(async vm => await vm.$store.dispatch('user/setUser', userID))
			} catch (e) {
				next({ name: 'home' })
			}
		},

		async beforeRouteUpdate(to, from, next) {
			try {
				await this.$store.dispatch('user/clearUser')
				next()
			} catch (e) {
				next({ name: 'home' })
			}
		},

		async beforeRouteLeave(to, from, next) {
			try {
				await this.$store.dispatch('user/clearUser')
				next()
			} catch (e) {
				next({ name: 'home' })
			}
		}
	}
</script>